<template>
  <div
    class="cardWallet shadow relative"
    style="flex-direction: column; max-width: 200px"
    :style="{ background: walletType }"
  >
    <!-- <div
      v-if="wallet.name == 'close' && accomplish"
      @click="openAchievements"
      class="accomplishments"
    >
      Accomplishments
    </div> -->
    <div
      class="card-holder-center"
      @click="openTranfer(wallet.id, wallet)"
    >
      <div
        v-if="wallet.name == 'main'"
        class="holder-desc"
      >
        Spend wallet
      </div>
      <div
        v-else-if="wallet.name == 'close'"
        class="holder-desc"
      >
        Save wallet
      </div>
      <div
        v-else-if="wallet == 'total'"
        class="holder-desc"
      >
        Total Balance
      </div>
      <div class="holder-price">
        <span class="poundSign">&pound;</span>{{
          wallet.name == "main" || wallet.name == "close"
            ? formatPrice(wallet.current_balance)
              ? formatPrice(wallet.current_balance)
              : formatPrice(0)
            : formatPrice(
              user.accounts
                .map((account) => account.current_balance)
                .reduce((a, b) => a + b)
            )
        }}
      </div>
    </div>
    <img
      v-if="wallet.name == 'main'"
      src="../assets/images/save-wallet-ill.svg"
      alt="Save Wallet"
      style="margin-top: 10px"
    >
    <img
      v-if="wallet.name == 'close'"
      src="../assets/images/spend-wallet-ill.svg"
      alt="Spend Wallet"
      style="margin-top: 10px"
    >
    <img
      v-if="wallet == 'total'"
      src="../assets/images/total-wallet-ill.svg"
      alt="Total Wallet"
      style="margin-top: 10px"
    >
  </div>
</template> 

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      wallet: {
        type: Object,
        default: () => {},
      },
      dashboard: {
        type: Boolean,
        default: false,
      },
      accomplish: {
        type: Boolean,
      },
    },
    computed: {
      ...mapGetters(["user"]),
      // walletType() {
      //   return this.wallet.name === "main"
      //     ? "linear-gradient(180deg, #FFD44F 0%, rgba(255, 212, 79, 0) 100%), #FFF8CE"
      //     : "linear-gradient(180deg, #FF4F79 0%, rgba(255, 79, 121, 0) 100%), #F5B0AE";
      // },
      walletType() {
        if (this.wallet.name === "main") {
          return "#58C1E1";
        } else if (this.wallet.name === "close") {
          return "#EC6C6B";
        } else if (this.wallet === "total") {
          return "#F6A42C";
        } else {
          return null;
        }
      },
      // walletTypeText() {
      //   return this.wallet.name === "main" ? "#7A615A" : "#FFFFFF";
      // },
    },
    methods: {
      openTranfer() {
        this.$emit("emitOpenTranferModal", true);
      },
      openAchievements() {
        this.$emit("emitOpenAchievementsModal", true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cardWallet {
    width: 100%;
    height: 130px;
    border-radius: 13px;
    margin: 0;
    position: relative;
    // overflow: hidden;
    color: white;
    margin-bottom: 0;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ffd44f),
        to(rgba(255, 212, 79, 0))
      ),
      #fff8ce;
    background: linear-gradient(180deg, #ffd44f 0%, rgba(255, 212, 79, 0) 100%),
      #fff8ce;
  }
  .sk-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .chip {
    position: absolute;
    top: 50px;
    right: 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    overflow: hidden;
  }
  .chip img {
    object-fit: contain;
    width: 80%;
  }
  .card-holder {
    position: absolute;
    top: 55px;
    left: 0;
    margin: 0px 25px;
    font-size: 20px;
    line-height: 28px;
  }
  .card-holder-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 25px;
    font-size: 20px;
    line-height: 28px;
  }
  .robot-spend {
    background: url("../assets/images/robot-blue.png");
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    background-size: contain;
    position: absolute;
    bottom: -30px;
    left: -10px;
    z-index: 100;
    transform: rotate(20deg);
  }
  .robot-save {
    background: url("../assets/images/robot_red.png");
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    background-size: contain;
    position: absolute;
    bottom: -30px;
    left: -10px;
    z-index: 100;
    transform: rotate(20deg);
  }
  .holder-price {
    font-size: 30px;
    font-weight: 700;
  }
  .holder-desc {
    font-size: 14px;
    font-weight: 700;
    // letter-spacing: -0.3px;
  }
  .poundSign {
    font-size: 18px;
  }

  .accomplishments {
    position: absolute;
    bottom: -27px;
    right: 50%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 0 13px;
    height: 30px;
    font-size: 12px;
    background: var(--yellow);
    border-radius: 10px;
    color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
